import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { supabase } from '../../service/api.tsx'
import About from '../../components/About/About.tsx'
import Card from '../../components/Card/Card.tsx'
import './home.css'
import {gsap} from 'gsap'
export default function Home() {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            let {data, error} = await supabase
            .from('projects')
            .select("id, name, shortdescription, thumbnail, project_descriptions(description), project_images(img), project_tags(tag)")
            .order('id')
            if (error) console.error(error)
            else setProjects(data)
        } 
        fetchData()
    }, [])
    useEffect(() => {
        gsap.from('.container-title', { duration: 1, opacity: 0, y: -50, ease: 'power3.out' });
        gsap.from('.title', { duration: 2, opacity: 0, x: -50, delay: 0.5, ease: 'power3.out' });
        gsap.from('.container-card', { duration: 1, opacity: 0, y: 50, stagger: 0.2, delay: 1, ease: 'power3.out' });
      }, []);
  return (
    <div>
            {/* SEO Metadata */}
            <Helmet>
                <title>Romain Danizel - Portfolio Developer FullStack JavaScript Freelance</title>
                <meta name="description" content="Discover my web development projects and me, showcasing my skills in JavaScript, React, Next.js, TypeScript, Mongodb. Available to work for your web projects" />
                <meta name="keywords" content="web development, React, Next.js, JavaScript, portfolio, developer fullstack, developer javascript, freelance" />
                <meta name="author" content="Romain Danizel" />

                {/* Open Graph (OG) for Social Media */}
                <meta property="og:title" content="Romain Danizel - Portfolio" />
                <meta property="og:description" content="Discover my latest web development projects." />
                <meta property="og:image" content="https://zmsyrobplmtyslsehkqt.supabase.co/storage/v1/object/public/Seo//thumbnailPortfolio.jpeg" />
                <meta property="og:url" content="https://www.romaindanizel.com/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card for Social Sharing */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="My Portfolio - Web Projects" />
                <meta name="twitter:description" content="Discover my latest web development projects." />
                <meta name="twitter:image" content="https://zmsyrobplmtyslsehkqt.supabase.co/storage/v1/object/public/Seo//thumbnailPortfolio.jpeg" />
            </Helmet>

            <About />

            <div className="container-title">
                <h2 className="title" id='projects'>My Projects</h2>
            </div>

            <div className="container">
                {projects.map((project) => (
                    <div className="container-card" key={project.id}>
                        <Card 
                            id={project.id} 
                            name={project.name} 
                            img={project.thumbnail} 
                            tags={project?.project_tags} 
                            description={project.shortdescription} 
                        /> 
                    </div>
                ))}
            </div>
        </div>
  )
}


