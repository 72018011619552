import React from 'react'
import {Link} from 'react-router'
import './header.css'
export default function Header() {
  return (
    <div className={"header"}>
        <div className={'container-nav'}>
            <Link to={'/'} className={"link link-header"}>Home</Link>
            <Link to={'/cv'} className={"link link-header"}>CV</Link>
            <a href='mailto:romain.danizel.pro@gmail.com' className={"link link-header"}>Contact</a>
        </div>
        
        <div className={"container-title"}>
            <h2 className={"title title-header"}>Romain Danizel</h2>
        </div>
    </div>
  )
}
