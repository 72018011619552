import React from 'react'
import { Helmet } from 'react-helmet'
import './cv.css'
export default function Cv() {
  return (
    <div>
        <Helmet>
                <title>Romain Danizel - CV</title>
                <meta name="description" content="Discover my web development projects and me, showcasing my skills in React, Next.js, TypeScript, Mongodb." />
                <meta name="keywords" content="web development, React, Next.js, JavaScript, portfolio" />
                <meta name="author" content="Romain Danizel" />

                {/* Open Graph (OG) for Social Media */}
                <meta property="og:title" content="Romain Danizel - CV" />
                <meta property="og:description" content="Discover my latest web development projects and everything you want to know about me." />
                <meta property="og:image" content="https://zmsyrobplmtyslsehkqt.supabase.co/storage/v1/object/public/Seo//thumbnailPortfolio.jpeg" />
                <meta property="og:url" content="https://www.romaindanizel.com/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card for Social Sharing */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="My Portfolio - Web Projects" />
                <meta name="twitter:description" content="Discover my latest web development projects." />
                <meta name="twitter:image" content="https://zmsyrobplmtyslsehkqt.supabase.co/storage/v1/object/public/Seo//thumbnailPortfolio.jpeg" />
            </Helmet>
    <div className='container'>
    <iframe title='curricalum vitae romain danizel' src="https://zmsyrobplmtyslsehkqt.supabase.co/storage/v1/object/public/Seo//CVENGROMAIN.pdf" className='image' width='100%' height='1000px'></iframe>
    </div>
    </div>
  )
}
