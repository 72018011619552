import React, {useEffect, useRef} from 'react'
import './card.css'
import { Link } from 'react-router-dom'
export default function Card({id, name, img, tags, description}) {

  return (
    <Link to={`/project/${id}`}>
    <div className={"card"}>
        <h2 className={"card-title"}>{name}</h2>
        <img src={img} alt="screen du projet"/>
        <div className={"container-tag"}>
            {tags.map((tag, i) => (
              <div>
              <p className={"tag"} key={i}>{tag.tag}</p>
              </div>
              ))}
        </div>
        <p className={"description"} >{description}</p>
    </div>
    </Link>
  )
}
