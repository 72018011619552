import {BrowserRouter, Routes, Route} from 'react-router'
import './App.css';
import Header from './components/Header.tsx';
import Home from './layers/Home/Home.tsx'
import Project from './layers/Project/Project.tsx';
import Cv from './layers/CV/Cv.tsx';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home  />}/>
          <Route path='/project/:id' element={<Project />} />
          <Route path='/cv' element={<Cv />} />
          <Route path='*' element={<h1>Not Found</h1>} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
