import React, { useEffect, useState, lazy, Suspense} from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { gsap } from 'gsap'
import { supabase } from '../../service/api.tsx'
import './project.css'

const DetailItem = lazy(() => import('../../components/Detail/Detail.tsx'));

export default function Project() {
 const { id } = useParams();
 const [project, setProject] = useState()
 const [loading, setLoading] = useState(true)
 const [details, setDetails] = useState([{descriptions: [], imgs: []}])
 const [arrTag, setArrTag] = useState([])
 const [sD, setSD] = useState()

 useEffect(() => {
  const fetchData = async () => {
    let { data, error } = await supabase
      .from('projects')
      .select("id, name, shortdescription, thumbnail, project_descriptions(description), project_images(img), project_tags(tag), project_links(link)")
      .eq('id', id)
      .single();
    
    if (error) {
      console.error(error);
    } else {
      setProject(data);
      setDetails([{ descriptions: data?.project_descriptions, imgs: data?.project_images, tags: data?.project_tags }]);
      setArrTag(data?.project_tags.map(tag => tag.tag));
      setLoading(false);
      setSD(data?.shortdescription)
    }
  };

  fetchData();
}, [id]);

useEffect(() => {
  if (!loading) {
    gsap.from('.project-main', { duration: 1, opacity: 0, y: -50 });
    gsap.from('.description', { duration: 1, opacity: 0, x: 50, delay: 1 });
    gsap.from('.container-details', { duration: 1, opacity: 0, scale: 0.5, delay: 1.5, stagger: 0.2 });
  }
}, [loading]);

  return (  
    <div>
      {loading && <p>Chargement...</p>}
      <Helmet>
        <meta name='description' content={`${sD}`}></meta>
        <meta name='keyword' content={`${arrTag.join(', ')}`}></meta>
      </Helmet>
      {project &&
      <div>
        <img className={'project-main'} src={project.thumbnail} alt="screen du projet" />
        <div className={'container-title v2'}>
          <h1 className={'title'}><a className='link title' href={project.project_links[0].link}>{project.name}</a></h1>
        </div>
        <div className={'container'}>
        <p className={"description"}>{project.shortdescription}</p>
        </div>
        <div className={"container"}>
  {details.map((detail, i) => (
    <Suspense fallback={<p>Chargement...</p>} key={i}>
      <DetailItem detail={detail} index={i}/>
    </Suspense>
  ))}
</div>
      </div>
      }
    </div>
  )
}
